/* eslint-disable jsx-a11y/alt-text */
import React from 'react'

const About = () => {
  return (
    <>
      <section className="PageBanner">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="pageTitle">
                <h1>About Us</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <main>
        <section className="custom-space">
          <div className="container">
            <div className="row gy-5 mb-5">
              <div className="col-lg-12">
                <div className="secInfo centerInfo">
                  <h6>About our</h6>
                  <h2>Real Gaming Platform</h2>
                  <p>Are you ready to take your gaming experience to the next level? Welcome to RGP, your premier platform for real money win games! RGP brings the thrill of gaming and the excitement of winning real cash prizes together in one seamless experience. Whether you're a casual gamer looking for some entertainment or a competitive player seeking lucrative rewards, RGP has something for everyone.</p>
                </div>
              </div>
              {/* <div className="col-lg-12">
                <img src="./img/mobile-view.png" className="img-responsive" />
              </div> */}
            </div>
        
            <div className="row gy-5">
              <div className="col-lg-12">
                <div className="aboutInfo">
                  <h4>What is RGP?</h4>
                  <p>RGP stands for Real Gaming Platform, a revolutionary app that offers a diverse range of games where players can compete against each other for real money prizes. From classNameic card games like Point Rummy and Speed Ludo to fast-paced arcade-style challenges, RGP hosts an extensive collection of games designed to cater to every taste and skill level.</p>
                </div>

                <div className="aboutInfo">
                  <h4>How Does RGP Work?</h4>
                  <p>Getting started with RGP is easy. Simply download the app, create an account, and you're ready to dive into the action. Once you're logged in, you can browse through the list of available games, join tournaments, or challenge other players in head-to-head competitions.</p>

                  <p>Each game on RGP features real money prizes that you can win by showcasing your skills and outplaying your opponents. Whether you're a seasoned pro or a newcomer to the world of online gaming, RGP offers a fair and competitive environment where everyone has a chance to win.</p>
                </div>

                <div className="aboutInfo">
                  <h4>Key Features of RGP</h4>
                  <p>Variety of Games: RGP offers a diverse selection of games ranging from traditional casino favorites to innovative multiplayer experiences. With new games added regularly, there's always something fresh and exciting to explore.</p>

                  <p>Real Money Prizes: Unlike traditional gaming apps, RGP gives you the opportunity to win real cash prizes based on your performance. Put your skills to the test and reap the rewards of your success.</p>

                  <p>Secure and Reliable: At RGP, security is our top priority. We utilize state-of-the-art encryption technology to ensure that your personal and financial information remains safe and secure at all times.</p>

                  <p>Accessible Anytime, Anywhere: Whether you're at home or on the go, RGP is available whenever you're in the mood for some gaming excitement. Simply launch the app on your smartphone or tablet and start playing instantly.</p>
                </div>

                <div className="aboutInfo">
                  <h4>Join RGP Today!</h4>
                  <p>Are you ready to unleash your competitive spirit and start winning real money prizes? Join the thousands of players who have already discovered the thrill of gaming on RGP. Download the app now and embark on an exhilarating journey where every game is an opportunity to win big!</p>

                  <p>Experience the future of gaming with RGP - where entertainment meets real rewards!</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default About