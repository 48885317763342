/* eslint-disable jsx-a11y/no-distracting-elements */
import React from 'react'

const Footer = () => {
  return (
    <footer className="overflow-hidden">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="about-footer">
              <a href="./" className="brandLogo">
                <img src="img/logo.png" alt="" />
              </a>
              <p>
                RGP is an online gaming platform where the competitive spirit of India comes alive. <br /> Play A+
                Rated games like Ludo, Rummy & more against each other in skill-games and win real money.
              </p>

              <marquee>
                Players from Telangana, Sikkim, Nagaland, Odisha, Assam, Arunachal Pradesh and Andhra Pradesh are not allowed to play this game for prizes.
              </marquee>

              <ul className="footer-nav">
                <li><a href="./">Home</a></li>
                <li><a href="./about">About</a></li>
                <li><a href="./terms">Terms & Conditions</a></li>
                <li><a href="./privacy-policy">Privacy Policy</a></li>
                <li><a href="./refund-policy">Refund Policy</a></li>
                <li><a href="./fairplay-policy">Fairplay Policy</a></li>
                <li><a href="./contact">Contact Us</a></li>
              </ul>
              <p className="copyright">© Copyright 2024 Real Gaming Platform. All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer