import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

const Home = () => {
   return (
      <>
         <section className="top-banner">
            <div className="container">
               <div className="row justify-content-end">
                  <div className="col-xl-6 col-lg-7">
                     <div className="banner-details">
                        <img src="./img/logo.png" alt="" className="appLogo" />
                        <h2>Multiple Players</h2>
                        <h1>Daily Winnings in Real Cash Games</h1>

                        <div className="downloadLink-container">
                           <h4>Download the App & Get ₹10 free!</h4>

                           <div className="getLink">
                              <a href="./apk/rgp_live.apk" className="ThemeBtn" download>Download App</a>
                           </div>

                           <div className="appRating">
                              <div className="ratingInfo">
                                 <div className="ratingCount">
                                    <i className="material-icons">star</i>
                                    <span>4.4</span>
                                 </div>
                                 <h6>Ratings</h6>
                              </div>

                              <div className="ratingInfo">
                                 <div className="ratingCount">
                                    <i className="material-icons">person</i>
                                    <span>5 Crore+</span>
                                 </div>
                                 <h6>Players</h6>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section className="custom-space">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="certificateContainer">
                        <div className="logoCard">
                           <img src="./img/Made_in_india.png" alt="" />
                        </div>

                        <div className="logoCard">
                           <img src="./img/No_Bots.png" alt="" />
                        </div>

                        <div className="logoCard">
                           <img src="./img/Secure_Payment.png" alt="" />
                        </div>

                        <div className="logoCard">
                           <img src="./img/Instant_Withdrawal.png" alt="" />
                        </div>

                        <div className="logoCard">
                           <img src="./img/Customer_support.png" alt="" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section className="custom-space bg-darklight">
            <div className="container">
               <div className="row gy-4 align-items-center">
                  <div className="col-lg-6">
                     <div className="secInfo">
                        <h6>Instantly</h6>
                        <h2>Withdraw Winnings</h2>
                        <p>This signifies that the process of withdrawing funds from the gaming platform is immediate or
                           very fast. Rather than waiting for a prolonged period, players can access their winnings almost
                           instantly after requesting a withdrawal.</p>
                     </div>
                  </div>

                  <div className="col-lg-6">
                     <div className="paymentCard">
                        <img src="./img/G_pay.png" alt="" className="paymentLogo" />
                        <img src="./img/Phone_pe.png" alt="" className="paymentLogo" />
                        <img src="./img/Paytm.png" alt="" className="paymentLogo" />
                        <img src="./img/BHIM.png" alt="" className="paymentLogo" />
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section className="custom-space">
            <div className="container">
               <div className="row gy-4 align-items-center flex-column-reverse flex-lg-row">
                  <div className="col-xl-7 col-lg-6">
                     <div className="reviewRatings">
                        <img src="./img/app-rating.png" alt="" />
                     </div>
                  </div>

                  <div className="col-xl-5 col-lg-6">
                     <div className="secInfo p-lg-5">
                        <h6>OUR PLAYERS</h6>
                        <h2>Trust RGP App</h2>
                        <p>Play real money games in India on highly trusted money game app RGP. Check out reviews from real
                           users below.</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section className="custom-space bg-darklight">
            <div className="container">
               <div className="row gy-5">
                  <div className="col-lg-12">
                     <div className="secInfo centerInfo">
                        <h6>How to</h6>
                        <h2>Install RGP App</h2>
                        <p>Steps to install the RGP real money game app & win money online are</p>
                     </div>
                  </div>

                  <div className="col-lg-12">
                     <div className="row gy-4">
                        <div className="col-lg-3 col-md-6">
                           <div className="cardBox">
                              <h6>Step 1</h6>
                              <span>Click “Download” Button to continue.</span>
                              <img src="./img/step-1.png" alt="" />
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                           <div className="cardBox">
                              <h6>Step 2</h6>
                              <span>Click on “Download Anyway” to start downloading.</span>
                              <img src="./img/step-2.png" alt="" />
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                           <div className="cardBox">
                              <h6>Step 3</h6>
                              <span>Turn on “Allow Apps” from this source.</span>
                              <img src="./img/step-3.png" alt="" />
                           </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                           <div className="cardBox">
                              <h6>Step 4</h6>
                              <span>Click on “Install” to play games & earn real cash.</span>
                              <img src="./img/step-4.png" alt="" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section className="custom-space">
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-lg-6">
                     <div className="secInfo">
                        <h6>EXPERIENCE our games</h6>
                        <h2>Skill Based Games</h2>
                        <p>Download real money earning app & start playing 15+ skills based online games to earn money</p>
                     </div>
                  </div>

                  <div className="col-gl-12">
                     <div className="ProductRow">
                        <div className="ProTitles">
                           <div className="ProductTitle">
                              <h2>Real Money Games</h2>
                           </div>

                           <div className="OurProducts">
                              <img src="./img/squar.png" alt="" />
                              <p>Our Games</p>
                           </div>
                        </div>

                        <div className="ProductSwiper">
                           <Swiper
                              className="GameSwiper"
                              spaceBetween={15}
                              slidesPerView={5}
                              speed={1000}
                              autoplay={{
                                 delay: 2500,
                                 disableOnInteraction: false
                              }}

                              breakpoints={{
                                 320: {
                                    slidesPerView: 1,
                                 },

                                 768: {
                                    slidesPerView: 3,
                                 },

                                 1024: {
                                    slidesPerView: 5,
                                 },
                              }}

                              modules={[Autoplay]}
                           >
                              <SwiperSlide>
                                 <div className="GameCard">
                                    <img src="./img/game3.png" alt="" />
                                 </div>
                              </SwiperSlide>
                              <SwiperSlide>
                                 <div className="GameCard">
                                    <img src="./img/card-soon.png" alt="" />
                                 </div>
                              </SwiperSlide>
                              <SwiperSlide>
                                 <div className="GameCard">
                                    <img src="./img/game3.png" alt="" />
                                 </div>
                              </SwiperSlide>
                              <SwiperSlide>
                                 <div className="GameCard">
                                    <img src="./img/card-soon.png" alt="" />
                                 </div>
                              </SwiperSlide>
                              <SwiperSlide>
                                 <div className="GameCard">
                                    <img src="./img/game3.png" alt="" />
                                 </div>
                              </SwiperSlide>
                              <SwiperSlide>
                                 <div className="GameCard">
                                    <img src="./img/card-soon.png" alt="" />
                                 </div>
                              </SwiperSlide>
                              <SwiperSlide>
                                 <div className="GameCard">
                                    <img src="./img/game3.png" alt="" />
                                 </div>
                              </SwiperSlide>
                              <SwiperSlide>
                                 <div className="GameCard">
                                    <img src="./img/card-soon.png" alt="" />
                                 </div>
                              </SwiperSlide>
                           </Swiper>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <div className="custom-space bg-darklight">
            <div className="container">
               <div className="row mb-4">
                  <div className="col-lg-12">
                     <div className="secInfo centerInfo">
                        <h6>REASONS TO</h6>
                        <h2>Play on RGP app</h2>
                        <p>Here are some of the reasons why users love to play on the RGP money game app.</p>
                     </div>
                  </div>
               </div>

               <div className="row gy-4">


                  <div className="col-lg-4 col-md-4">
                     <div className="cardBox">
                        <h4>5 Cr+ Real Players</h4>
                        <span>No bots in games</span>
                        <img src="./img/reason-img-2.png" alt="" />
                     </div>
                  </div>

                  <div className="col-lg-4 col-md-4">
                     <div className="cardBox">
                        <h4>1 Referral = ₹5</h4>
                        <span>Refer friends to earn</span>
                        <img src="./img/reason-img-3.png" alt="" />
                     </div>
                  </div>

                  <div className="col-lg-4 col-md-4">
                     <div className="cardBox">
                        <h4>24x7 Support</h4>
                        <span>Our team is at service</span>
                        <img src="./img/reason-img-6.png" alt="" />
                     </div>
                  </div>

                  <div className="col-lg-12">
                     <div className="cardBox">
                        <h4>Instant Withdrawals</h4>
                        <span>Directly to your bank account</span>
                        <img src="./img/reason-img-4.png" alt="" />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default Home