import Footer from "./component/Footer";
import Header from "./component/Header";
import MainRouter from "./MainRouter";

function App() {
  return (
    <div className="mainLayout">
      <Header/>
      <MainRouter/>
      <Footer/>
    </div>
  );
}

export default App;
