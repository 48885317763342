import React from 'react'

const RefundPolicy = () => {
  return (
    <>
      <section className="PageBanner">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="pageTitle">
                <h1>Refund Policy</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <main>
        <section className="custom-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="policy-wrapper">
                  <div className="description-info">

                    <p> Refund Policy At Real Gaming platform, we strive to provide our users with a
                      seamless and enjoyable gaming experience. We understand that there may be instances
                      where users encounter issues with deposits, withdrawals, or transactions on our
                      platform. </p>
                    <p> This refund policy outlines our approach to addressing such situations. </p>

                    <div className="description-list">
                      <h4>Refund Eligibility:</h4>
                      <ul>
                        <li>Refunds may be considered in the following circumstances:Technical errors or
                          system malfunctions resulting in unintended transactions or loss of
                          funds.Unauthorized transactions on the user's account.</li>
                        <li>Refund requests must be submitted within 7 days of the transaction date for
                          consideration.</li>
                      </ul>
                    </div>

                    <div className="description-list">
                      <h4>Refund Process:</h4>
                      <ul>
                        <li>Users must submit a refund request by contacting our customer support team
                          via mail on support@getrgp.com. The request must include:User account
                          details Transaction details (date, amount, transaction ID) Description of
                          the issue or reason for the refund request.</li>
                        <li>Our customer support team will review the request and investigate the
                          reported issue.</li>
                        <li>If the refund request is approved, the refunded amount will be credited back
                          to the user's account within 7 business days of approval.</li>
                      </ul>
                    </div>

                    <div className="description-list">
                      <h4>Refund Limitations:</h4>
                      <ul>
                        <li>Refunds will be issued only for transactions that meet the eligibility
                          criteria outlined in this policy.</li>
                        <li>Refunds will be processed in the original payment method used for the
                          transaction, whenever possible.</li>
                        <li>Refunds may be subject to processing fees or charges, which will be deducted
                          from the refunded amount.</li>
                      </ul>
                    </div>

                    <div className="description-list">
                      <h4>Dispute Resolution:</h4>
                      <ul>
                        <li>In the event of a dispute regarding a refund request, Real Gaming Platform
                          reserves the right to conduct further investigation and request additional
                          information from the user.</li>
                        <li>Users agree to cooperate with Real Gaming Platform in resolving any disputes
                          or discrepancies related to refund requests.</li>
                      </ul>
                    </div>

                    <div className="description-list">
                      <h4>Amendments to Policy:</h4>
                      <ul>
                        <li>Real Gaming Platform reserves the right to update or modify this refund
                          policy at any time without prior notice. Users are encouraged to review the
                          policy periodically for any changes.</li>
                      </ul>
                    </div>

                    <div className="description-list">
                      <h4>Contact Us:</h4>
                      <p>If you have any questions or concerns regarding our refund policy, please contact
                        our customer support team at support@getrgp.com.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default RefundPolicy