import React from 'react'
import { Route, Routes } from 'react-router-dom'
import About from './pages/About'
import Home from './pages/Home'
import RefundPolicy from './pages/RefundPolicy'
import FairplayPolicy from './pages/FairplayPolicy'
import Contact from './pages/Contact'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Terms from './pages/Terms'

const MainRouter = () => {
   return (
      <Routes>
         <Route path="/" element={<Home />} />
         <Route path="/home" element={<Home />} />
         <Route path="/about" element={<About />} />
         <Route path="/refund-policy" element={<RefundPolicy />} />
         <Route path="/fairplay-policy" element={<FairplayPolicy />} />
         <Route path="/contact" element={<Contact />} />
         <Route path="/privacy-policy" element={<PrivacyPolicy />} />
         <Route path="/terms" element={<Terms />} />
      </Routes>
   )
}

export default MainRouter