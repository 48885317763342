import React from 'react'

const Contact = () => {

   function submitForm(event) {
      event.preventDefault(); // Prevent default form submission

      var name = document.getElementById('name').value;
      var email = document.getElementById('email').value;
      var number = document.getElementById('number').value;
      var subject = document.getElementById('subject').value;
      var message = document.getElementById('message').value;

      // Simple validation
      if (name.trim() === '' || email.trim() === '' || number.trim() === '' || subject.trim() === '' || message.trim() === '') {
         alert('Please fill in all fields.');
         return;
      }

      var xhr = new XMLHttpRequest();
      var url = './send_email.php';
      xhr.open('POST', url, true);
      xhr.setRequestHeader('Content-Type', 'application/json');

      xhr.onreadystatechange = function () {
         if (xhr.readyState === 4) {
            if (xhr.status === 200) {
               // Handle success response
               alert('Message sent successfully!');
               // You can also redirect the user or perform any other action here
            } else {
               // Handle error response
               alert('Error: ' + xhr.statusText);
            }
         }
      };

      var data = JSON.stringify({
         name: name,
         email: email,
         number: number,
         subject: subject,
         message: message
      });

      xhr.send(data);
   }

   return (
      <>
         <section className="PageBanner">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="pageTitle">
                        <h1>Contact Us</h1>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <main>
            <section className="custom-space">
               <div className="container">
                  <div className="row mb-5">
                     <div className="col-lg-12">
                        <div className="contact-wrapper">
                           <div className="secInfo">
                              <h2>Keep in touch with us</h2>
                           </div>

                           <div className="contactInfo">
                              <div className="infobox">
                                 <h6>Merchant Legal entity name</h6>
                                 <p>CODEJACK TECHNOLOGY LLP</p>
                              </div>

                              <div className="infobox">
                                 <h6>Registered Address / Operational Address</h6>
                                 <ul>
                                    <li><b>Building No./Flat No.:</b>Ramesh Chandra</li>
                                    <li><b>Road/Street:</b>Gokul Singh</li>
                                    <li><b>City/Town/Village:</b>Birakhedi</li>
                                    <li><b>District:</b>Dewas</li>
                                    <li><b>State:</b>Madhya Pradesh</li>
                                    <li><b>PIN Code:</b>455001</li>
                                 </ul>
                              </div>

                              <div className="infobox">
                                 <h6>E-Mail</h6>
                                 <a href="mailto:support@getrgp.com">support@getrgp.com</a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="row gy-4" id="contactForm">
                     <div className="col-lg-12">
                        <div className="secInfo">
                           <h2>Drop Your message</h2>
                        </div>
                     </div>

                     <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                           <input type="text" placeholder="Your name" className="form-control" name="name" id="name" />
                        </div>
                     </div>

                     <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                           <input type="email" placeholder="Your email" className="form-control" name="email" id="email" />
                        </div>
                     </div>

                     <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                           <input type="number" placeholder="Your number" className="form-control" name="number" id="number" />
                        </div>
                     </div>

                     <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                           <input type="text" placeholder="Your Subject" className="form-control" name="subject" id="subject" />
                        </div>
                     </div>

                     <div className="col-lg-12">
                        <div className="form-group">
                           <textarea name="message" id="message" placeholder="Write a message" className="form-control" rows="5" ></textarea>
                        </div>
                     </div>

                     <div className="col-lg-12">
                        <div className="form-group">
                           <button type="button" className="ThemeBtn" onClick={submitForm}>Submit</button>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </main>
      </>
   )
}

export default Contact