import React from 'react'

const FairplayPolicy = () => {
  return (
    <>
      <section className="PageBanner">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="pageTitle">
                <h1>Fireplay Policy</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <main>
        <section className="custom-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="policy-wrapper">
                  <h6 className="last-updateDate">Last updated on 03-03-2024</h6>
                  <div className="description-info">

                    <div className="description-list">
                      <h5>Welcome to RGP We are committed to providing a fun and inclusive environment for
                        all players. To ensure that everyone has an enjoyable experience, we ask that
                        all players adhere to the following Fairplay Policy:</h5>
                      <ul>
                        <li><b>Real Players Only:</b> We strive to ensure that we only have genuine
                          users playing on the RGP app. To facilitate this, we have strict policies
                          and protocols to check any violation of our Fairplay Policy.</li>
                        <li><b>No Collusion Tolerated:</b> The users participating in real money games
                          and colluding with others will be banned from the RGP app.</li>
                        <li><b>No Cheating Allowed:</b> We do not allow hacking in any form, including
                          using bugs, third-party tools or multiple accounts to access the RGP app,
                          and such behaviour will lead to the user being banned from the RGP app.</li>
                        <li><b>Secure Payments:</b> We work with a third-party payment gateway,
                          Authentication mechanism, to ensure the safety and security of all financial
                          transactions. We do not store users’ credit/debit card details.</li>
                        <li><b>Instant Withdrawals:</b> We have a seamless instant withdrawal process to
                          ensure hassle-free withdrawal for users.</li>
                        <li><b>Security & Privacy Ensured:</b> We use standard data security practices
                          and tools to ensure the security and privacy of user data. All our systems
                          are regularly tested using automated and manual processes for security.</li>
                        <li><b>Game Abandonment Upon Disconnection:</b> Games disconnected due to poor
                          internet connectivity will be declared abandoned, and users will lose the
                          game. We advise users to check their internet connection before they start
                          playing. RGP will not be responsible for the internet connections of
                          individual players.</li>
                        <li><b>Returns Dependant on Skill:</b> The probability of return from playing
                          games offered on the RGP app is dependent on the skill being exhibited by a
                          user. In other words, the returns are the same for each player who displays
                          a certain degree of skill.</li>
                        <li><b>Game Fraud Detection:</b> We have automated detection systems to identify
                          fraudulent behaviour in games.</li>
                        <li><b>Real-time Match-making With Real Players:</b> The users on the RGP app
                          are paired with other real users in real-time, depending on when they
                          initiate the gameplay. They are matched with other real people who also
                          initiate the gameplay for the same game.</li>
                        <li><b>Swift & Easy Communication:</b> We have set up processes within the RGP
                          app to ensure that users can easily communicate with our customer support
                          staff. We also ensure swift response timelines and complete support to the
                          users.</li>
                      </ul>
                      <p><b>Note:</b> If we determine, at our sole discretion, that a user has violated
                        our Fairplay Policy, then we may take actions against such users as we deem fit,
                        including but not limited to suspending/terminating their account on the RGP
                        app.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default FairplayPolicy