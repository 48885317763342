import React, { useEffect } from 'react'

const Header = () => {

   useEffect(() => {
      const handleScroll = () => {
         var header = document.querySelector('header');
         if (window.pageYOffset >= 150) {
            header.classList.add('sticky-header');
         } else {
            header.classList.remove('sticky-header');
         }
      };

      window.addEventListener('scroll', handleScroll);

      // Clean up the event listener when component unmounts
      return () => {
         window.removeEventListener('scroll', handleScroll);
      };
   }, []);

   return (
      <header>
         <nav className="navbar navbar-expand-lg">
            <div className="container">
               <div className="header-nav">
                  <a className="navbar-brand" href="./">
                     <img src="img/logo.png" alt="" />
                  </a>

                  <div className="navigation-container">
                     <div className="navigation">
                        <button className="navbar-toggler ToggleButton" type="button" data-bs-toggle="collapse"
                           data-bs-target="#sideaNav" aria-controls="sideaNav" aria-expanded="false"
                           aria-label="Toggle navigation">
                           <span></span>
                           <span></span>
                           <span></span>
                           <div className="nav-overlay" data-bs-toggle="collapse" data-bs-target="#sideaNav"></div>
                        </button>

                        <div className="collapse navbar-collapse" id="sideaNav">
                           <a href="./" className="sidenav-logo d-lg-none">
                              <img src="img/logo.png" alt="" />
                           </a>

                           <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                              <li className="nav-item">
                                 <a href="./" className="nav-link">
                                    Home
                                 </a>
                              </li>

                              <li className="nav-item">
                                 <a href='./about' className="nav-link">About Us</a>
                              </li>

                              <li className="nav-item">
                                 <a href="./refund-policy" className="nav-link">Refund Policy</a>
                              </li>

                              <li className="nav-item">
                                 <a href="./fairplay-policy" className="nav-link">Fairplay Policy</a>
                              </li>

                              <li className="nav-item">
                                 <a href="./contact" className="nav-link">Contact us</a>
                              </li>
                           </ul>
                        </div>
                     </div>

                     <ul className="contact-as-nav">
                        <li>
                           <a href="./apk/rgp_live.apk" className="ThemeBtn" download>Download App</a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </nav>
      </header>
   )
}

export default Header